import React from "react"
// import { Link } from "gatsby"

import Layout from "../../components/layout"
// import Week from "../components/week"
// import Schedule from "../components/schedule"
import MiniLayout from "../../components/minimalLayout"


const Corporate = ({data}) => {

  return (
    <Layout>

      <MiniLayout>
        <h2>Reading: <i>I Have Nothing to Say and I’m Saying It: Net Art’s Corporate Turns</i> by Michael Connor</h2>
        <p>published in <a target="_blank" href="https://rhizomedotorg.myshopify.com/products/the-art-happens-here-net-art-anthology">I Was Raised On The Internet</a> by Omar Kholeif (2018).</p>
        <br></br>
        <p>Michael Connor is Artistic Director of Rhizome. Rhizome is a non-for-profit arts organization in New York that dedicates itself to new media art, and takes a key role in supporting, archiving and preserving art on the internet. Connor also oversaw the Net Art Anthology initiative, a web-based exhibition, gallery exhibition, and book that retold the history of online art through 100 artworks from the 1980s to the present.</p>
        <br></br>
        <p><b>Part 1:</b> Pick <b>one</b> of the below projects and read through the linked article about it on the Net Art Anthology.</p>
        <p>- <a target="_blank" href="https://anthology.rhizome.org/samsung">Samsung (1999)</a> by Young-hae Chang Heavy Industries</p>
        <p>- <a target="_blank" href="https://anthology.rhizome.org/black-net-art-actions">Black Net.Art Actions (2001 - 2003)</a> by Mendi + Keith Obadike</p>
        <p>- <a target="_blank" href="https://anthology.rhizome.org/airworld">Airworld (1999)</a> by Jennifer and Kevin McCoy</p>
        <p>- <a target="_blank" href="https://anthology.rhizome.org/the-web-stalker">The Web Stalker (1997)</a> by I/O/D</p>
        <p>- <a target="_blank" href="https://anthology.rhizome.org/floodnet">FloodNet (1998)</a> by the Electronic Disturbance Theater (EDT)</p>
        <p>- <a target="_blank" href="https://anthology.rhizome.org/youth-mode-a-report-on-freedom">Youth Mode: A Report on Freedom (2013)</a> by K-HOLE & Box</p>

        <br></br>
        <p><b>Part 2:</b> Read <i>I Have Nothing to Say and I’m Saying It: Net Art’s Corporate Turns</i> (2018), a piece written by Michael Connor. It situates various artworks within the history of art in an increasingly corporate context of the internet. (<a target="_blank" href="https://drive.google.com/file/d/1vKOYj8dca0we2btLAo0_6r8StwKOCM-M/view?usp=sharing">NYU access</a>).</p>
        <p>As you read, write 300-500 words. Try to discuss the artwork you picked in Part 1 in light of the reading. The below notes can help:</p>
        <p>- reflect on the statement that “context is king” and how this context manifests itself before and after the internet’s “corporate turn”.</p>
        <p>- how does the artistic strategy of creating “work that contains a relatively strong political and social intent, but is clothed […] in ironic ambiguity” apply to your chosen artwork?</p>
        <p>- what’s the relation of the artwork you picked to the “archive” discussed in the text?</p>

        <br></br>
        <p><b>Submit your work:</b></p>
        <p>1) Complete your writing in a markdown file (.md) inside your repository.</p>
        <p>2) When done, push your work to your GitHub account and <a target="_blank" href="https://github.com/leoneckert/abc-browser-circus/wiki">submit a link at our class Wiki.</a></p>
      </MiniLayout>
    </Layout>
  )
}



export default Corporate
